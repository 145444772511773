// Ukrainian
export default{
    form: {
        startSurveyButton: "Почати",
        nextButton: "Далі",
        submitAnswerButton: "Надіслати відповідь",
        continueButton: "Продовжити",
        finishButton: "Завершити",
        headerPercentageCompletion: 'виконано',
        headerQuestionsCompleted: 'питань',
        headerDefaultErrorMessage: 'Упс. Щось пішло не так.',
        brandingPoweredBy: 'Працює на',
        brandingPromoWhySurvey: 'Навіщо опитування, якщо можна використовувати <b>{product}</b>?',
        brandingPromoGetStarted: 'Почніть сьогодні!',
        submissionPending: "Ваш voiceform надсилається. Будь ласка, не закривайте вікно браузера.",
        submissionCanCloseWindow: "Ваш voiceform успішно надісланий. Тепер ви можете закрити вікно 👍",
        mediaAttachmentOverlayCloseButton: "Закрити",
        expiredFormHeader: "Дякуємо за відвідування!",
        expiredFormMessage: "Схоже, що це опитування завершилося. Будь ласка, зв'яжіться з творцем voiceform.",
        notPublishedFormHeader: "Voiceform не опубліковано!",
        notPublishedFormMessage: "Схоже, що це опитування ще не опубліковано. Будь ласка, зв'яжіться з творцем voiceform.",
        notPublishedFormRetryButton: "Спробувати знову",
        errorFormHeader: "Упс!",
        errorFormMessage: "Можливо, щось пішло не так. Будь ласка, спробуйте пізніше.",
        errorFormRetryButton: "Спробувати знову",
        emptyFormHeader: "Дякуємо за відвідування!",
        emptyFormMessage: "Упс! Ця форма зараз порожня і не містить питань чи полів для заповнення. Вибачте за незручності. Будь ласка, зв'яжіться з творцем форми, щоб виправити це. Дякуємо за розуміння та співпрацю!",

        restoreFormHeader: "Схоже, ви маєте незавершену форму!",
        restoreFormMessage: "Хочете продовжити з того місця, де зупинилися?",
        restoreFormButton: "Продовжити форму",
        restoreFormButtonNew: "Почати заново",

        audioPermissionHeader: "Ви хочете відповісти голосом?",
        audioPermissionMessage: "Голосова відповідь зробить процес швидшим і простішим.",
        audioPermissionAcceptButton: "Так, хочу",
        audioPermissionDenyButton: "Ні, дякую",
        audioPermissionErrorHeader: "Мікрофон недоступний",
        audioPermissionErrorMessage: "Будь ласка, перевірте дозволи браузера для доступу до мікрофона.",
        audioPermissionErrorButton: "Добре",
        audioRecorderFailedUpload: "Не вдалося завантажити запис",
        silenceDetectorError: "Ми вас не чуємо. Перевірте налаштування мікрофона.",
        audioRecorderButtonRecord: "Записати",
        audioRecorderButtonRecordMore: "Записати більше",
        voiceResponseSelectionMessage: "Виберіть, як хочете відповісти...",
        voiceResponseSelectionOrDivider: "або",
        voiceResponseTextInputPlaceholder: "Введіть відповідь",
        voiceResponseTextInputCharacterCounterMinMessage: "Будь ласка, введіть принаймні {min} символів",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} символів (мінімум {min})",
        voiceResponseTextInputCharacterValidationMessage: "{count} символів (мінімум {min})",

        // checkbox
        checkboxValidationTooFew: "Ви повинні вибрати принаймні {min} варіант(и)",
        checkboxValidationTooMany: "Ви можете вибрати не більше {max} варіант(и)",
        checkboxNoneOfTheAboveOption: "Ніщо з вищезазначеного",
        checkboxInvalidAnswer: "Невірна відповідь.",

        // datepicker
        datePickerPlaceholder: "Виберіть дату",

        // dropdown
        // email
        emailLabel: "Електронна пошта",
        emailInvalid: "Невірна електронна пошта.",
        // file-upload
        fileUploadPluginNameCamera: "Камера",
        fileUploadPluginNameCameraVideo: "Записати відео",
        fileUploadPluginScreenCast: "Запис екрану",
        fileUploadVideoImportFilesDefault: 'Записати або завантажити відео через:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Виберіть опцію запису відео:',
        fileUploadVideoImportFilesNoCamera: 'Натисніть кнопку, щоб записати екран',
        fileUploadVideoImportFilesNoScreenCast: 'Натисніть кнопку, щоб записати відео',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Немає доступних варіантів для запису відео',

        // matrix
        matrixValidationMessage: "Відповідь дійсна.",
        matrixRow: "Рядок",
        matrixColumn: "Стовпець",
        matrixRowRequired: "Рядок {rowTitle} обов'язковий.",
        matrixRadioGroup: "Радіо-група",
        matrixCheckboxGroup: "Група з прапорцями",
        matrixGroupRequired: "Для рядка {rowTitle} потрібна {type} {groupTitle}.",
        matrixColumnRequired: "Для рядка {rowTitle} потрібен стовпець {columnTitle}.",
        matrixColumnInvalid: "Для рядка {rowTitle} стовпець {columnTitle} недійсний.",
        matrixRequired: "Обов'язкове.",
        matrixNumericMustBeNumber: "Повинно бути числом.",
        matrixNumericMustBeGreaterThenMin: "Повинно бути більше за {min}.",
        matrixNumericMustBeLessThenMax: "Повинно бути менше за {max}.",
        matrixNumericMustBeInteger: "Повинно бути цілим числом.",
        matrixNumericInvalidNumber: "Невірне число.",

        // name
        nameLabel: "Ім'я",
        nameInvalid: "Вкажіть ім'я та прізвище.",

        // nps
        npsNotLikely: "Майже неможливо",
        npsExtremelyLikely: "Дуже ймовірно",

        // numeric input
        numericInputRequired: "Обов'язкове.",
        numericInputMustBeNumber: "Повинно бути числом.",
        numericInputMustBeGreaterThenMin: "Повинно бути більше за {min}.",
        numericInputMustBeLessThenMax: "Повинно бути менше за {max}.",
        numericInputMustBeInteger: "Повинно бути цілим числом.",
        numericInputInvalidNumber: "Невірне число.",
        numericInputPlaceholder: "Введіть число",

        // phone
        phoneInvalid: "Невірний номер телефону.",
        phoneCountrySelectorLabel: 'Код країни',
        phoneCountrySelectorError: 'Виберіть країну',
        phoneNumberLabel: 'Номер телефону',
        phoneExample: 'Приклад:',

        // boolean
        booleanYesLabel: "Так",
        booleanNoLabel: "Ні",

        //questionStep
        questionStepAudioQuestionLabel: "Аудіо-питання",

        // errors
        invalidPhoneNumber: "{phone} невірний номер телефону.",
        invalidEmail: "{email} невірна електронна адреса.",
        questionIsRequired: "Це питання обов'язкове.",
        answerIsNotValid: "Відповідь недійсна.",
        unfinishedProbingDialogError: "Будь ласка, завершіть діалог.",
        cannotResumePartialResponse: "Не вдалося продовжити часткову відповідь.",
        failedToSubmitForm: "Не вдалося надіслати форму. Перевірте підключення до інтернету та спробуйте ще раз."
    }
}

